<template>
  <div class="container_su">
    <div
      class="
        flex
        justify-between
        align-center
        bg-white
        p-l-r-20 p-t-b-10
        radius-4
        m-b-20
      "
    >
      <div>数据简报</div>
      <div>
        <el-date-picker
          v-model="searchQuery.startDate"
          size="small"
          type="date"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          placeholder="选择日期"
        ></el-date-picker>
      </div>
    </div>

    <div>
      <el-row :gutter="10">
        <el-col :span="6">
          <div class="bg-white p-15 radius-4">
            <div class="item">
              <div class="top p-b-10 m-b-10">
                <div class="item-title font-14 m-b-10 color-info">
                  申请费用（元）
                </div>
                <div class="item-content font-20">
                  {{ topData.amount || 0 }}
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="bg-white p-15 radius-4">
            <div class="item">
              <div class="top p-b-10 m-b-10">
                <div class="item-title font-14 m-b-10 color-info">
                  实际消费(元)
                </div>
                <div class="item-content font-20">
                  {{ topData.usedAmount || 0 }}
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :span="6">
          <div class="bg-white p-15 radius-4">
            <div class="item">
              <div class="top p-b-10 m-b-10">
                <div class="item-title font-14 m-b-10 color-info">
                  通过审批（次）
                </div>
                <div class="item-content font-20">
                  {{ topData.numbers || 0 }}
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :span="6">
          <div class="bg-white p-15 radius-4">
            <div class="item">
              <div class="top p-b-10 m-b-10">
                <div class="item-title font-14 m-b-10 color-info">
                  当前余额（元）
                </div>
                <div class="item-content font-20">
                  {{ balance }}
                  <i
                    class="el-icon-refresh m-l-10 pointer"
                    @click="updateBalance"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="table-container bg-white m-b-20 p-20 radius-4">
      <div class="table-top m-b-20">按部门统计</div>
      <el-table :data="list" border fit highlight-current-row>
        <el-table-column label="部门名称" prop="name" />
        <el-table-column label="申请金额" prop="applicationAmount" />
        <el-table-column label="实际消费" prop="usedAmount" />
        <el-table-column label="通过审批" prop="numbers" />
      </el-table>
    </div>
    <!-- <el-row :gutter="10">
      <el-col :span="8">
        <div class="col-box">
          <department-pie :list="list"></department-pie>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="col-box">
          <department-line></department-line></div
      ></el-col>
      <el-col :span="8">
        <div class="col-box">
          <department-bar :list="list"></department-bar></div
      ></el-col>
    </el-row> -->
  </div>
</template>
<script>
// import departmentLine from "./components/line.vue";
// import departmentPie from "./components/pie.vue";
// import departmentBar from "./components/bar.vue";
// import { getTreeList } from "@/api/admin/department";
import api from "@/api/common";
export default {
  components: {
    // departmentLine, departmentPie, departmentBar
  },
  data() {
    let self = this;
    return {
      searchQuery: {
        startDate: self.$publicMethods.formatDate(new Date(), 4),
        endDate: "",
      },
      balance: 0,
      list: [],
      topData: {
        numbers: 0,
        amount: 0,
        usedAmount: 0,
        usableAmmount: 0,
      },
    };
  },
  watch: {
    searchQuery: {
      handler() {
        this.fetchData();
        this.getStatistical();
      },
      deep: true, //开启深度监听
    },
  },
  created() {
    this.getSessionStorage();
    this.fetchData();
    this.getStatistical();
  },
  mounted() {},
  methods: {
    async updateBalance() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let res = await api.commonGet({
        apiName: "/meituanInvoice/accountQuery",
      });
      if (res.code == 200) {
        this.$message.success("余额更新成功！");
        this.getBalance();
        loading.close();
      }
    },
    async getBalance() {
      let res = await api.commonGet({
        apiName: "/meituanInvoice",
        pageSize: 200,
      });
      let arr = res.data.data.filter((item) => {
        return item.departId == sessionStorage.getItem("departmentId");
      });
      if (arr.length > 0) {
        this.balance = arr[0].balance;
      } else {
        this.balance = "暂无数据";
      }
    },
    // 获取管理员
    getSessionStorage() {
      let item = sessionStorage.getItem("roleName");
      console.log("item", item);
      this.getBalance();
    },
    // 获取头部数据

    async getStatistical() {
      let data = {
        startDate: `${this.searchQuery.startDate} 00:00:00`,
        endDate: `${this.searchQuery.startDate} 23:59:59`,
        userId: sessionStorage.getItem("userId"),
      };
      let res = await api.commonGet({
        apiName: "/meituanApplication/Statistical",
        ...data,
      });
      if (res.code == 200) {
        this.topData = res.data;
      }
    },
    //
    // 获取树形列表
    async fetchData() {
      let result = await api.commonGet({
        apiName: "/meituanDepartmentStatistics/child",
        startDate: `${this.searchQuery.startDate} 00:00:00`,
        endDate: `${this.searchQuery.startDate} 23:59:59`,
        userId: sessionStorage.getItem("userId"),
        departId: sessionStorage.getItem("departmentId"),
      });
      if (result.code == 200) {
        // 获取到所有部门，过滤分局部门，然后在剔除机场分局部门

        // console.log(result.data);
        this.list = result.data;
        // let list = result.data
        //   .filter((x) => x.id == 1181048293760913410)
        //   .map((x) => {
        //     return x.children;
        //   })[0]
        //   .filter((x) => x.id != 1181048870536433665);
        // this.list = list;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.item {
  .color-info {
    color: #999;
  }
  .top {
    border-bottom: 1px solid #f1f1f1;
    .item-title {
    }
  }
}
.pointer {
  cursor: pointer;
}
.table-top {
  // border: 1px solid #EBEEF5;
  // border-bottom: 0;
}
.red {
  color: #f56c6c;
}
.green {
  color: #67c23a;
}
</style>
